<template>
  <div class="home">
    <AppBar />
    <v-content>
      <v-container fluid>
        <HelloWorld msg="Welcome to Your Vue.js App" />
      </v-container>
    </v-content>
  </div>
</template>

<script>
// @ is an alias to /src
import AppBar from "@/components/AppBar.vue";
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
    AppBar
  },
  beforeCreate() {
    document.title = this.$i18n.t("welcome");
  }
};
</script>
